.header-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fbf5ef;
	/* position: relative; */
	height: 650px;
	/* top: 0; */
}

.header-background {
	width: 100%;
	height: 100%;
	/* Set height to 100vh */
	object-fit: cover;
}

.header-content {
	width: 800px;
	text-align: center;
	background-color: #0000005e;
	padding: 20px 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 5px;
}

.header-content h2 {
	font-size: 32px;
	color: #f1f1f1;
	font-family: "Varela Round", sans-serif;
	font-style: italic;
	letter-spacing: 1px;
	line-height: 2;
	text-shadow: 3px 4px 7px rgba(0, 0, 0, 0.548);
	text-transform: uppercase;
}

.aboutMe {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 100px 0;
	background-color: #fbf5ef;
}

.aboutMeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* Vertically align items */
	width: 80%;
	gap: 40px;
	justify-content: center;
}

.aboutMeImagesContainer {
	display: flex;
	/* flex-direction: column;  */
	width: 100%;
	gap: 10px;
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	/* Arrange items vertically (top to bottom) */
	align-items: flex-start;
	/* Align items to the left side */
	flex: 2;
	font-family: "Varela rounded", sans-serif;
	text-align: justify;
	font-size: 16px;
	line-height: 2;
	letter-spacing: 1px;
}

.contentWrapper>h2 {
	padding-bottom: 20px;
}

.aboutMeImg {
	flex: 1;
	width: 100%;
	object-fit: cover;
	height: auto;
	border-radius: 5px;
}

.learnMoreButton {
	display: inline-block;
	padding: 10px 20px;
	background-color: #47494b;
	color: #fff;
	text-decoration: none;
	border-radius: 5px;
	font-size: 16px;
	transition: background-color 0.3s ease;
	margin-top: 40px;
	/* Add some top margin to separate the button from the paragraphs */
}

.learnMoreButton:hover {
	background-color: #bcbcbc;
	/* Set a darker shade on hover */
	color: #000000;
	/* You can also add other styles like changing text color on hover */
}

.fadeIn {
	opacity: 0;
	transition: opacity 1s ease;
	/* Adjust the transition duration as needed */
}

.fadeIn.show {
	opacity: 1;
}

.centered {
	width: 100%;
	text-align: center;
}

.card-container {
	width: 100%;
	display: flex;
	justify-content: center;
}

.card {
	border: none;
	border-radius: 15px;
	width: 50%;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
	margin-bottom: 20px;
}

.card-body {
	padding: 40px;
}

.rounded-circle {
	border-radius: 50%;
}

.author-img {
	width: 125px;
	padding-top: 5px;
	padding-bottom: 5px;
}

blockquote {
	font-size: 18px;
	font-style: italic;
	color: #333;
	margin-bottom: 20px;
}

.blockquote-footer {
	font-size: 16px;
	color: #777;
}

.author-image-container {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}

/* Adjust the styles as needed for your design */

@media screen and (max-width: 992px) {
	.aboutMe {
		padding: 50px 0;
	}

	.aboutMe {
		padding-bottom: 30px;
	}

	.header-content {
		width: 600px;
	}

	.header-content h2 {
		font-size: 27px;
	}

	.aboutMeContainer {
		flex-direction: column;
		/* Change the flex-direction to column for smaller screens */
	}

	.aboutMeImg {
		width: 100%;
		height: auto;
		border-radius: 5px;
	}

	.learnMoreButton {
		padding: 8px 16px;
		font-size: 16px;
	}

	.card {
		width: 80%;
		box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
		margin-bottom: 20px;
		text-justify: auto;
	}
}

@media screen and (max-width: 768px) {
	.header-content {
		width: 500px;
		padding: 10px 20px;
	}

	.header-content h2 {
		font-size: 20px;
	}

	.header-content h2 {
		font-size: 24px;
	}

	.learnMoreButton {
		font-size: 14px;
	}

	.aboutMeContainer {
		gap: 40px;
	}

	.card {
		width: 100%;
	}
}

@media screen and (max-width: 576px) {
	.header-content {
		width: 250px;
		top: 60%;
		padding: 5px 10px;
	}

	.centered {
		text-align: center;
	}

	.header-content h2 {
		font-size: 20px;
	}

	.learnMoreButton {
		font-size: 14px;
	}
}