/* Hide the scrollbar but allow scrolling */
body::-webkit-scrollbar {
	width: 0.0000001rem;
	/* Adjust the width of the scrollbar as needed */
	background: transparent;
	/* Set the scrollbar background color to transparent */
}

/* Original styles for larger screens */

.aboutPost {
	flex: 9;
	width: 80%;
	margin: 0 auto;
	margin-top: 175px;
	padding-bottom: 100px;
	background-color: #fbf5ef;
}

.aboutPostWrapper {
	padding: 50px 0;
	min-height: 420px;
}

.aboutPostImg {
	/* width: 400px; */
	height: 400px;
	border-radius: 5px;
	object-fit: contain;
	float: left;
	margin-right: 20px;
}

.aboutPostTitle {
	margin: 10px;
	font-family: "Varela Round", sans-serif;
	text-align: center;
	font-size: 28px;
	margin-top: 10px;
}

.aboutPostEdit {
	float: right;
	font-size: 16px;
}

.aboutPostIcon {
	margin-left: 10px;
	cursor: pointer;
}

.aboutPostIcon:first-child {
	color: teal;
}

.aboutPostIcon:last-child {
	color: tomato;
}

.aboutPostInfo {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	font-family: "Varela Round", sans-serif;
	color: #b39656;
}

.aboutPostDesc {
	color: #666;
	font-size: 18px;
	line-height: 1.5;
	text-align: justify;
	font-family: "Varela rounded", sans-serif;
	letter-spacing: 1px;
}

p::first-letter {
	margin-left: 30px;
}

.aboutPostDesc>p:first-child:first-letter {
	margin-left: 30px;
	font-size: 30px;
	font-weight: 600;
}

.quote {
	font-family: "Varela Round", sans-serif;
	font-size: 16px;
	padding: 10px 20px;
	color: #666;
	line-height: 24px;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	text-align: justify;
	font-style: italic;
}

.quoteAuthor {
	text-align: right;
	font-weight: bold;
}

.aboutProfession {
	display: flex;
	text-align: center;
	font-family: "Varela Round", sans-serif;
	font-size: 14px;
	margin-top: 0 !important;
	padding-bottom: 10px;
	color: #666;
	line-height: 24px;
	margin-top: 20px;
	flex-direction: column;
	font-style: italic;
}

.bold-text {
	font-weight: bold;
}

.mt-mb-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}

ul {
	list-style: none;
	padding-left: 0;
	margin-left: 30px;
}

ul li.tick-shaped:before {
	content: "\2713";
	color: black;
	font-size: 25px;
	padding-right: 10px;
}

p>.click-here {
	font-weight: 600;
	text-decoration: none;
	color: #666;
}

p>.click-here:hover {
	text-decoration: none;
	font-weight: bold;
}

.aboutPostDesc .click-here {
	display: inline-block;
	position: relative;
	color: #666;
	/* Change the color as needed */
	text-decoration: none;
	/* Remove underline */
}

.aboutPostDesc .click-here::after {
	content: "";
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 0;
	left: 0;
	background-color: #000000ca;
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.aboutPostDesc .click-here:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

/* Media Query for Tablet Devices (max-width: 992px) */
@media screen and (max-width: 992px) {
	.aboutPost {
		width: 90%;
	}

	/* .aboutPostImg {
		width: 100%;
		height: auto; 
		margin-right: 0; 
		float: none;  
	} */
	.aboutPostWrapper {
		padding: 25px 0;
	}

	ul li.heart-shaped:before {
		font-size: 18px;
		padding-right: 10px;
	}
}

/* Media Query for Mobile Devices (max-width: 768px) */
@media screen and (max-width: 768px) {
	.aboutPostTitle {
		font-size: 24px;
		/* Decrease the title font size for smaller screens */
	}

	.aboutPostDesc {
		font-size: 16px;
		/* Decrease the description font size for smaller screens */
	}

	/* .aboutPostImg {
		width: 100%;
		height: auto;
		margin-right: 0;
		float: none;
	} */

	.aboutPost {
		width: 90%;
	}

	.aboutPostWrapper {
		padding: 10px 0;
	}

	ul li.heart-shaped:before {
		font-size: 14px;
		padding-right: 10px;
	}
}

/* Media Query for Mobile Phones (max-width: 576px) */
@media screen and (max-width: 576px) {
	.aboutPostTitle {
		font-size: 20px;
		/* Decrease the title font size for smaller screens */
	}

	.aboutPostDesc {
		font-size: 14px;
		/* Decrease the description font size for smaller screens */
	}

	.aboutPostImg {
		width: 100%;
		height: auto;
		margin-right: 0;
		float: none;
		object-fit: cover;
	}

	.aboutPostWrapper {
		padding: 5px 0;
	}

	ul li.heart-shaped:before {
		font-size: 14px;
		padding-right: 5px;
	}
}