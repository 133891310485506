.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactIcon {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    margin-right: 10px;
    color: #444;
    cursor: pointer;
}

.contactIcon i {
    font-size: 20px;
}

.contactIcon .fa-phone {
    margin-right: 5px;
}

.contactIcon .fa-envelope {
    margin-left: 5px;
}

.contactWrapper {
    width: 100%;
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-right: 0;
}

.contactTitle {
    text-align: center;
    margin: 10px;
    font-family: "Lora", serif;
    font-size: 28px;
}

.contactDesc {
    color: #666;
    display: flex;
    max-width: 300px;
    flex-direction: column;
    gap: 30px;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
}

.contactDesc a {
    text-decoration: none;
    color: inherit;
    font-family: "Lora", serif;
    font-size: 20px;
}

/* Media Query for Medium Screens (max-width: 992px) */
@media screen and (max-width: 992px) {
    .contactWrapper {
        gap: 30px; /* Reduce the gap between elements for medium screens */
    }

    .contactDesc {
        max-width: 100%; /* Make the contact description full width for medium screens */
        font-size: 16px; /* Reduce the font size for medium screens */
    }

    .contactTitle {
        font-size: 24px; /* Reduce the font size for medium screens */
    }
}

/* Media Query for Small Screens (max-width: 768px) */
@media screen and (max-width: 768px) {
    .contactWrapper {
        gap: 20px; /* Reduce the gap between elements for smaller screens */
    }

    .contactDesc {
        max-width: 100%; /* Make the contact description full width for smaller screens */
        font-size: 16px; /* Reduce the font size for smaller screens */
    }

    .contactTitle {
        font-size: 22px; /* Reduce the font size for smaller screens */
    }
}

/* Media Query for Extra Small Screens (max-width: 576px) */
@media screen and (max-width: 576px) {
    .contactWrapper {
        gap: 20px; /* Reduce the gap between elements for extra small screens */
    }

    .contactDesc {
        max-width: 100%; /* Make the contact description full width for extra small screens */
        font-size: 14px; /* Reduce the font size for extra small screens */
    }

    .contactTitle {
        font-size: 20px; /* Reduce the font size for extra small screens */
    }
}
